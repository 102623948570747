export const photos = [
  {
    src: require('../images/about-photo-01.gif'),
    width: 4,
    height: 3
  },
  {
    src: require('../images/about-photo-02.png'),
    width: 1,
    height: 2
  },
  {
    src: require('../images/about-photo-16.png'),
    width: 4,
    height: 3
  },
  {
    src: require('../images/about-photo-04.png'),
    width: 5,
    height: 4
  },
  {
    src: require('../images/about-photo-03.JPG'),
    width: 3,
    height: 4
  },
  {
    src: require('../images/about-photo-05.png'),
    width: 3,
    height: 4
  },
  {
    src: require('../images/about-photo-06.png'),
    width: 1,
    height: 1
  },
  {
    src: require('../images/about-photo-07.gif'),
    width: 3,
    height: 2
  },
  {
    src: require('../images/about-photo-08.png'),
    width: 2,
    height: 3
  },
  {
    src: require('../images/about-photo-09.png'),
    width: 16,
    height: 9
  },
  {
    src: require('../images/about-photo-10.png'),
    width: 4,
    height: 3
  },
  {
    src: require('../images/about-photo-11.png'),
    width: 4,
    height: 3
  },
  {
    src: require('../images/about-photo-12.png'),
    width: 3,
    height: 4
  },
  {
    src: require('../images/about-photo-14.png'),
    width: 3,
    height: 1
  },
  {
    src: require('../images/about-photo-15.png'),
    width: 4,
    height: 3
  },
  {
    src: require('../images/about-photo-13.png'),
    width: 16,
    height: 9
  }
];
